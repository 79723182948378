// Core
.ant-col {
  padding: 0 0.5rem;
  font-family: "Nunito", sans-serif;
}

// Typography
.ant-typography {
  display: block;
  font-family: "Nunito", sans-serif;
  line-height: 28px;
  color: $color-grey;
}

// Button
.ant-btn-default,
.ant-btn-text {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  height: fit-content;
  color: $color-white;
  padding: 14px 35px;
  border-radius: 5px;
  font: {
    weight: 600;
    family: "Nunito", sans-serif;
  }
  background: transparent;
  border: 1px solid $color-white;
  transform: rotateX(20deg);
  transition: all 0.4s;

  svg {
    position: relative;
    top: -1px;
  }

  &:disabled {
    color: $color-white;
    opacity: 0.4;
  }

  &:hover {
    color: $color-black !important;
    background: $color-white;
    border-color: transparent !important;
    transform: rotateX(0deg);

    svg {
      g path {
        fill: $color-black;
      }
    }
  }

  &.xs {
    padding: 10px 25px;
  }
}

.ant-btn-text {
  padding: 10px 25px;
  border: none;

  &:hover {
    color: $color-white !important;
    background: transparent;
    border-color: transparent !important;
    transform: rotateX(0deg);

    svg {
      g path {
        fill: $color-white;
      }
    }
  }
}

// Form | Input
.ant-form {
  color: $color-grey;
  font-family: "Nunito", sans-serif;
}

.ant-input {
  min-height: 40px;
  border: none;
  font-family: "Nunito", sans-serif;
  color: $color-white;
  background: $background-grey-200;
  outline: 1px solid $background-grey-200 !important;
  outline-offset: 3px;
  transition: all 0.3s;

  &:hover {
    outline-color: $color-outline-input !important;
  }

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: $color-grey;
  }

  &.ant-input-status-error {
    outline: 1px solid $error-border !important;
  }
}

.ant-form-item {
  .ant-form-item-row {
    flex-direction: column;
  }

  .ant-form-item-label {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    white-space: initial;
    text-align: start;

    label {
      padding: 0 0.5rem;
      color: $color-grey;

      &.ant-form-item-required {
        &:not(.ant-form-item-required-mark-optional) {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.ant-form-item-with-help {
  .ant-form-item-explain {
    color: #d94c48;
    margin-top: 3px;
    font: {
      size: 12px;
      family: "Nunito", sans-serif;
    }
  }
}

.ant-input-affix-wrapper {
  padding: 0px 11px;
  border: none;
  background: $background-grey-200;
  outline: 1px solid $background-grey-200 !important;
  outline-offset: 3px;

  .ant-input {
    outline: none !important;
  }

  .ant-input-clear-icon {
    display: flex;
  }

  &:hover {
    outline-color: $color-outline-input !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &::placeholder {
    color: $color-grey;
  }

  &.ant-input-affix-wrapper-status-error {
    outline: 1px solid $error-border !important;
  }

  &.ant-input-password {
    .ant-input-suffix {
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 70%;
        top: 50%;
        left: -10px;
        background-color: $element-color;
        transform: translateY(-50%);
      }
    }
  }
}

// Picker
.ant-picker {
  width: 100%;
  min-height: 40px;
  border: none;
  color: $color-white;
  background: $background-grey-200;
  outline: 1px solid $background-grey-200 !important;
  outline-offset: 3px;
  transition: all 0.3s;

  .ant-picker-input {
    font-family: "Nunito", sans-serif;

    input {
      color: $color-white;

      &::placeholder {
        color: $color-grey;
      }
    }

    input[disabled] {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .ant-picker-clear {
    background: $background-grey-200;
  }

  &:not(.ant-picker-disabled) {
    &.ant-picker-status-error {
      background-color: $background-grey-200 !important;
      outline: 1px solid $error-border !important;

      &:hover {
        background: $background-grey-200 !important;
      }
    }
  }
}

.ant-picker-focused {
  box-shadow: none;
  outline-color: $color-outline-input !important;
}

.ant-picker-dropdown {
  font-family: "Nunito", sans-serif;

  .ant-picker-panel-container {
    margin: 5px 0;
    background: $background-color;
    outline: 1px solid $background-color !important;
    outline-offset: 3px;
  }

  .ant-picker-header {
    color: $color-white;

    .ant-picker-header-view button {
      &:hover {
        color: $color-main;
      }
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .ant-picker-content {
    th {
      color: $color-white;
    }

    .ant-picker-cell-in-view {
      color: $color-white !important;

      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background: $color-main;
        }
      }

      &.ant-picker-cell-today {
        .ant-picker-cell-inner {
          &::before {
            border: none;
            outline: 1px solid $color-main !important;
            outline-offset: 2px;
          }
        }
      }
    }

    .ant-picker-cell {
      font-weight: 600;
      color: #696969;

      &:hover:not(.ant-picker-cell-selected):not(
          .ant-picker-cell-range-start
        ):not(.ant-picker-cell-range-end):not(
          .ant-picker-cell-range-hover-start
        ):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        background: $element-color;
      }
    }
  }

  .ant-picker-time-panel-column {
    li {
      &.ant-picker-time-panel-cell-selected {
        .ant-picker-time-panel-cell-inner {
          color: $color-main !important;
          background: $background-grey-200;
        }
      }

      &.ant-picker-time-panel-cell {
        .ant-picker-time-panel-cell-inner {
          color: $color-white;

          &:hover {
            background: $element-color;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: $element-color;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-main;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $color-main;
    }
  }

  .ant-picker-ranges {
    gap: 15px;
    line-height: 40px;
    justify-content: center;

    .ant-picker-ok {
      margin-inline-start: unset;
    }
  }

  .ant-picker-panel {
    .ant-picker-footer {
      margin: 5px;
      border-radius: 6px;
      min-width: calc(100% - 10px);
      background: $element-color;
      border-top: none;
    }
  }

  .ant-picker-today-btn {
    padding: 1px 7px;
    border-radius: 4px;
    color: $color-white !important;
    font-family: "Nunito", sans-serif;
    outline: 1px solid $color-main !important;
    outline-offset: 3px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }

  .ant-picker-week-panel-row-selected {
    td {
      &.ant-picker-cell:before {
        background-color: $color-outline-input;
      }
    }
  }

  .ant-picker-cell-disabled {
    opacity: 0.3;
  }
}

// Alert
.ant-alert {
  font: {
    size: 12px;
    family: "Nunito", sans-serif;
  }
  border: none;
  margin: 0 0.5rem;

  .ant-alert-message {
    text-align: left;
  }

  &.ant-alert-success {
    color: #25ca7f;
    background: rgba(37, 202, 127, 0.15);
  }

  &.ant-alert-info {
    color: #00a3e1;
    background: rgba(0, 163, 225, 0.15);
  }

  &.ant-alert-warning {
    color: #bcb727;
    background: rgba(188, 183, 39, 0.15);
  }

  &.ant-alert-error {
    color: #d94c48;
    background: rgba(217, 76, 72, 0.15);
  }

  .ant-alert-icon {
    margin-top: 1px;
  }
}

// Number Input
.ant-input-number {
  width: 100%;
  border: none;
  box-shadow: none;
  border-radius: 6px;
  background-color: $background-grey-200;
  outline: 1px solid $background-grey-200 !important;
  outline-offset: 3px;
  transition: outline 0.3s;

  .ant-input-number-input {
    height: 40px;
    color: $color-white;
    font-family: "Nunito", sans-serif;

    &::placeholder {
      color: $color-grey;
    }
  }

  &.ant-input-number-disabled {
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;

    &:hover {
      outline-color: #383b40 !important;
    }
  }

  .ant-input-number-handler-wrap {
    height: 80%;
    top: 50%;
    background: $background-grey-200;
    transform: translateY(-50%);
  }

  .ant-input-number-handler {
    border-inline-start: none;

    &:hover {
      opacity: 0.5;
    }
  }

  .ant-input-number-handler-down {
    border-block-start: none;
  }

  &:first-child {
    border-start-end-radius: 6px;
    border-end-end-radius: 6px;
  }

  &:focus {
    box-shadow: none;
  }

  &:hover {
    outline-color: $color-outline-input !important;
  }
}

.ant-input-number-group-wrapper {
  width: 100%;

  .ant-input-number-group {
    border-radius: 6px;
    outline: 1px solid $background-grey-200 !important;
    outline-offset: 3px;
    transition: outline 0.3s;

    .ant-input-number {
      display: block;
      width: calc(100% - 3px);
      border: none;
      box-shadow: none;
      background-color: $background-grey-200;
      outline: none !important;

      .ant-input-number-input {
        height: 40px;
        color: $color-white;
        font-family: "Nunito", sans-serif;

        &::placeholder {
          color: $color-grey;
        }
      }

      .ant-input-number-handler-wrap {
        height: 80%;
        top: 50%;
        background: $background-grey-200;
        transform: translateY(-50%);
      }

      .ant-input-number-handler {
        border-inline-start: none;

        &:hover {
          opacity: 0.5;
        }
      }

      .ant-input-number-handler-down {
        border-block-start: none;
      }

      &:first-child {
        border-start-end-radius: 6px;
        border-end-end-radius: 6px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .ant-input-number-group-addon {
      color: $color-white;
      border: none;
      background-color: $background-grey-200;

      &:last-child {
        border-start-start-radius: 6px;
        border-end-start-radius: 6px;
      }

      .ant-select {
        &.ant-select-single {
          .ant-select-selector {
            height: 40px;
            border: none;
            box-shadow: none;
            color: $color-white;
            font-family: "Nunito", sans-serif;
            outline: none !important;

            .ant-select-selection-item {
              line-height: 39px;
            }
          }

          &.ant-select-show-arrow {
            .ant-select-selection-item {
              position: relative;
              top: 1px;
              padding-inline-end: 22px;
            }
          }

          &.ant-select-open {
            .ant-select-selection-item {
              color: $color-main;
              box-shadow: none;
            }
          }
        }
      }
    }

    &:hover {
      outline-color: $color-outline-input !important;
    }
  }

  &.ant-input-number-group-wrapper-status-error {
    .ant-input-number-group {
      outline: 1px solid $error-border !important;
    }
  }
}

// 24. Table
.ant-table-wrapper {
  border-radius: 10px;

  .ant-table {
    font-family: "Nunito", sans-serif;
    background: transparent;
    border-radius: 10px;

    .ant-table-filter-trigger {
      color: #ababab;
    }

    .ant-table-thead {
      tr {
        th {
          color: $color-grey;
          background: $background-color;
          border: none;

          &::before {
            background-color: $background-grey-200 !important;
          }
        }

        &:first-child {
          > *:first-child {
            border-radius: 8px 0 0 8px;
          }
        }

        &:last-child {
          > *:last-child {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          color: $color-grey;
          background: $element-color;
          border: none;

          &:first-child {
            border-radius: 10px 0px 0px 10px;
          }

          &:last-child {
            border-radius: 0px 10px 10px 0px;
          }
        }

        .main,
        .edit,
        .delete {
          display: flex;
          align-items: center;
          justify-content: center;

          a,
          .ant-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px;
            border-radius: 8px;
            background: rgba(0, 163, 225, 0.1);
          }
        }

        .main {
          .ant-btn {
            background: rgba(0, 195, 154, 0.1);
          }
        }

        .delete {
          .ant-btn {
            background: rgba(217, 76, 72, 0.1);
          }
        }
      }

      .ant-table-row {
        .ant-table-cell-row-hover {
          background: rgba(34, 35, 37, 0.3);
        }
      }
    }
  }

  .ant-table-container::after {
    display: none;
  }

  table {
    border-radius: 10px;
    border-spacing: 0 10px;
  }

  .ant-table-tbody {
    tr.ant-table-placeholder {
      border-radius: 10px !important;
      background: transparent;

      td {
        border-radius: 10px !important;
        background: $element-color !important;
      }
    }
  }
}

// Pagination
.ant-pagination {
  .ant-pagination-item {
    font-family: "Nunito", sans-serif;
    background-color: $element-color;

    a {
      transition: all 0.3s;
      color: $color-grey;
    }

    &:hover {
      background-color: $element-color !important;
      outline: 1px solid $element-color !important;
      outline-offset: 2px;

      a {
        color: $color-main;
        opacity: 0.5;
      }
    }
  }

  .ant-pagination-item-active {
    border: none;
    background-color: rgba(0, 195, 154, 0.1);
    outline: 1px solid rgba(0, 195, 154, 0.3) !important;
    outline-offset: 2px;

    a {
      color: $color-main;
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    background-color: transparent;

    .ant-pagination-item-container {
      .ant-pagination-item-ellipsis {
        font-family: "Nunito", sans-serif;
        color: $color-grey;
      }

      .ant-pagination-item-link-icon {
        font-family: "Nunito", sans-serif;
        color: $color-main;
      }
    }
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    .ant-pagination-item-link {
      color: $color-grey;
      background-color: $element-color !important;
      outline-color: $element-color !important;

      button {
        border: none;
      }
    }

    &:hover {
      .ant-pagination-item-link {
        background-color: $element-color !important;
        outline: 1px solid $element-color !important;
        outline-offset: 2px;

        span {
          opacity: 0.5;
        }
      }
    }

    &.ant-pagination-disabled {
      .ant-pagination-item-link {
        opacity: 0.5;
      }
    }
  }
}

// Divider
.ant-divider {
  border-block-start: 1px solid $element-color;
}

// Modal
.ant-modal {
  color: $color-grey;
  font-family: "Nunito", sans-serif;

  .ant-modal-content {
    margin: 20px 0;
    border-radius: 5px;
    background-color: $element-color;
  }

  .ant-modal-header {
    position: relative;
    border-radius: 0;
    margin-bottom: 15px;
    padding-bottom: 15px;
    background-color: unset;

    .ant-modal-title {
      color: $color-grey;
      font: {
        weight: 400;
        size: 20px;
      }
      text-align: left;
    }
  }

  .ant-modal-close {
    width: 24px;
    height: 24px;
    top: 22px;
    inset-inline-end: 22px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &.modal-closed {
    .ant-form-item {
      .ant-form-item-row {
        gap: 7px;
      }
    }

    .text-modal {
      font-size: 18px;
      font-weight: 400;

      b {
        color: $color-main;
      }
    }
  }
}

// Tabs Panel
.ant-tabs {
  &.ant-tabs-card {
    .ant-tabs-nav {
      padding: 10px;
      border-radius: 10px;
      background-color: $element-color;

      &::before {
        display: none;
      }

      .ant-tabs-nav-wrap {
        overflow-x: auto;
      }

      .ant-tabs-nav-list {
        width: 100%;
        gap: 10px;
        padding-bottom: 5px;
        overflow: auto;

        .ant-tabs-tab {
          width: 100%;
          justify-content: center;
          color: $color-grey;
          font: {
            size: 16px;
            font-family: "Nunito", sans-serif;
          }
          padding: 0;
          border: none;
          border-radius: 5px;
          background: $background-color;

          .ant-tabs-tab-btn {
            display: flex;
            align-items: center;
            gap: 7px;
            padding: 15px;
          }

          &.ant-tabs-tab-active {
            color: $color-main;
            background-color: $background-main;

            .ant-tabs-tab-btn {
              color: $color-main;

              svg {
                g {
                  path,
                  circle {
                    fill: $color-main;
                  }
                }
              }
            }
          }
        }
      }

      .ant-tabs-nav-operations {
        display: none;
      }
    }
  }
}

// Side Bar
.ant-menu {
  background-color: transparent;

  &.ant-menu-dark {
    .ant-menu-item-selected {
      color: $color-main;
      background-color: rgba(0, 195, 154, 0.1);
    }
  }
}

.ant-menu-inline {
  &.ant-menu-root {
    .ant-menu-item {
      height: 55px;
      line-height: 55px;
      font-size: 15px;
      margin-bottom: 10px;
      padding: 10px !important;
      font-family: "Nunito", sans-serif;

      .ant-menu-title-content {
        text-align: left;
      }
    }
  }
}

// Select
.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    min-height: 40px;
    border: none;
    font-family: "Nunito", sans-serif;
    color: $color-white;
    background: $background-grey-200;
    outline: 1px solid $background-grey-200 !important;
    outline-offset: 3px;
    box-shadow: none !important;
    transition: all 0.3s;

    .ant-select-selection-search-input {
      min-height: 40px;
      font-family: "Nunito", sans-serif;
    }

    .ant-select-selection-item {
      line-height: 40px;

      img {
        max-width: 18px;
        margin-right: 5px;
      }
    }

    &:hover {
      outline-color: $color-outline-input !important;
    }

    .ant-select-selection-placeholder {
      line-height: 40px;
      color: $color-grey;
    }
  }
}

.ant-select-single {
  height: 40px;
  text-align: left;

  &.ant-select-open {
    .ant-select-selection-item {
      color: $color-white;
    }
  }
}

.ant-select-dropdown {
  font-family: "Nunito", sans-serif;
  margin: 5px 0;
  background: $background-color;
  outline-offset: 3px;

  .ant-select-item {
    color: $color-white;

    &:hover {
      background: $element-color;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: $color-main !important;
    background: $background-grey-200;
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    gap: 7px;

    img {
      max-width: 18px;
    }
  }
}

.ant-select-multiple {
  .ant-select-selection-item {
    background: rgba(0, 0, 0, 0.5);
  }

  .anticon.anticon-close {
    color: #d94c48;
  }

  .ant-select-selection-item-content {
    line-height: 20px !important;
  }
}

// Collapse
.ant-collapse {
  .ant-collapse-item {
    border: none;
    color: $color-grey;
    font-family: "Nunito", sans-serif;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: $background-color;

    .ant-collapse-header {
      .ant-collapse-expand-icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }

      .ant-collapse-arrow {
        transition: all 0.3s;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ant-collapse-content {
    border-color: $element-color;
    background-color: $background-color;
    border-radius: 0 0 10px 10px;

    .ant-collapse-content-box {
      color: $color-grey;
      font-family: "Nunito", sans-serif;
    }
  }

  &.inactive {
    pointer-events: none;
  }
}

// Empty
.ant-empty-normal {
  .ant-empty-description {
    color: $color-grey;
    font-family: "Nunito", sans-serif;
  }
}

// Filter Dropdown
.ant-dropdown {
  .ant-table-filter-dropdown {
    padding: 10px;
    background: $element-color;

    input {
      margin-bottom: 15px !important;
    }

    .ant-btn-default {
      height: 30px;

      .ant-btn-icon {
        margin: 0 !important;
      }
    }

    .ant-btn-link {
      color: $color-red;

      &:hover {
        color: $color-red;
        opacity: 0.5;
      }
    }
  }
}
